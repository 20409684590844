'use client';

import { cva } from 'class-variance-authority';
import { InfoIcon } from 'lucide-react';
import {
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import cn from '../lib/cn';

const wrapperClassName = cva(undefined, {
  variants: {
    variant: {
      primary: 'focus-within:border-content-brand-primary',
      warning: 'border-content-warning',
      error: 'border-content-danger',
      safe: 'border-content-safe',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});
const inputClassName = cva(undefined, {
  variants: {
    size: {
      sm: 'py-[7px] b3',
      lg: 'py-[11px] b2',
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

export type InputVariant = 'primary' | 'warning' | 'error' | 'safe';
export type InputSize = 'sm' | 'lg';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  variant?: InputVariant;
  bigness?: InputSize; // synonym to size, size attribute in HTMLInputElement shows the size of character, use bigness instead
  classNames?: {
    wrapper?: string;
    input?: string;
  };
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      variant = 'primary',
      bigness = 'lg',
      disabled,
      readOnly,
      classNames,
      leftSection,
      rightSection,
      ...props
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => innerRef.current!, []);
    return (
      <div
        className={cn(
          // layout
          'flex items-center gap-2 rounded-lg overflow-clip px-3 cursor-text',
          // border
          'border border-content-border',
          // color
          'transition-colors bg-surface-primary [&:focus-within>.text-content-secondary]:text-content-primary [&:has(>input:not(:placeholder-shown):not(:disabled):not(:read-only))>.text-content-secondary]:text-content-primary',
          wrapperClassName({
            variant,
          }),
          readOnly && 'border-black-50 bg-surface-tertiary',
          disabled && 'border-black-50 bg-transparent',
          classNames?.wrapper,
        )}
        onClick={() => {
          if (innerRef.current) {
            innerRef.current.focus();
          }
        }}
      >
        {leftSection ? (
          <div className="w-4 h-4 text-content-secondary [&>svg]:w-4 [&>svg]:h-4">
            {leftSection}
          </div>
        ) : null}
        <input
          ref={innerRef}
          {...props}
          className={cn(
            // layout
            'w-full pl-0 pr-0 rounded-[inherit] focus:outline-none focus:ring-0',
            // border
            'border-none',
            // color
            'text-content-primary bg-transparent placeholder:text-content-tertiary',
            // disabled
            'disabled:cursor-not-allowed disabled:text-content-tertiary',
            // readonly
            'read-only:text-content-tertiary',
            inputClassName({
              size: bigness,
            }),
            className,
            classNames?.input,
          )}
          disabled={disabled}
          readOnly={readOnly}
        />
        {rightSection ? (
          <div className="w-4 h-4 text-content-secondary [&>svg]:w-4 [&>svg]:h-4">
            {rightSection}
          </div>
        ) : null}
      </div>
    );
  },
);
Input.displayName = 'Input';

export type InputLabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  disabled?: boolean;
  readOnly?: boolean;
};

const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ className, disabled, readOnly, ...props }, ref) => {
    return (
      <label
        ref={ref}
        {...props}
        className={cn(
          'b3 font-semibold text-content-primary',
          disabled && 'text-content-tertiary',
          readOnly && 'text-content-tertiary',
          className,
        )}
      />
    );
  },
);
InputLabel.displayName = 'InputLabel';

const inputDescriptionClassName = cva(undefined, {
  variants: {
    variant: {
      primary: 'text-black',
      warning: 'text-content-warning',
      error: 'text-content-danger',
      safe: 'text-content-safe',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export type InputDescriptionProps = HTMLAttributes<HTMLParagraphElement> & {
  variant?: InputVariant;
  disabled?: boolean;
  readOnly?: boolean;
  classNames?: {
    icon?: string;
    text?: string;
  };
};

const InputDescription = forwardRef<
  HTMLParagraphElement,
  InputDescriptionProps
>(
  (
    { className, variant, disabled, readOnly, children, classNames, ...props },
    ref,
  ) => {
    return (
      <p ref={ref} {...props} className={cn('flex items-center', className)}>
        <InfoIcon
          size={16}
          className={cn(
            inputDescriptionClassName({
              variant,
            }),
            'mr-1',
            disabled && 'text-content-tertiary',
            readOnly && 'text-content-tertiary',
            classNames?.icon,
          )}
        />
        <span
          className={cn(
            inputDescriptionClassName({
              variant,
            }),
            's1 font-medium',
            disabled && 'text-content-tertiary',
            readOnly && 'text-content-tertiary',
            classNames?.text,
          )}
        >
          {children}
        </span>
      </p>
    );
  },
);
InputDescription.displayName = 'InputDescription';

export { InputLabel, Input, InputDescription };
