'use client';

import { compareAsc, format } from 'date-fns';
import { CalendarDays } from 'lucide-react';
import * as React from 'react';
import {
  DateRange,
  PropsBase,
  PropsMulti,
  PropsMultiRequired,
  PropsRange,
  PropsRangeRequired,
  PropsSingle,
  PropsSingleRequired,
} from 'react-day-picker';

import { Button } from '../button';
import { Calendar } from '../calendar';
import { Input, InputSize } from '../input';
import cn from '../lib/cn';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../popover';

export type DatePickerSpecificProps = PropsBase & {
  id?: string;
  size?: InputSize;
  triggerClassnames?: {
    wrapper?: string;
    input?: string;
  };
};

export type DatePickerProps = DatePickerSpecificProps &
  (
    | PropsSingle
    | PropsSingleRequired
    | PropsMulti
    | PropsMultiRequired
    | PropsRange
    | PropsRangeRequired
    | {
        mode?: undefined;
        required?: undefined;
      }
  ) & {
    id?: string;
    size?: InputSize;
  };

/* eslint-disable react/prop-types */
function DatePicker(props: DatePickerProps) {
  switch (props.mode) {
    case 'single':
      return <DatePickerSingle {...props} />;
    case 'range':
      return <DatePickerRange {...props} />;
    default:
      return <></>;
  }
}

const DatePickerSingle: React.FC<
  DatePickerSpecificProps & (PropsSingle | PropsSingleRequired)
> = ({ selected, onSelect, size = 'lg', triggerClassnames, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState<Date | undefined>(selected);

  const showDate = () => {
    if (selected) return format(selected, 'LLL dd, y');
    return '';
  };

  const onApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (date) {
      onSelect && onSelect(date, date, {}, e);
      setOpen(false);
    }
  };

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) setDate(selected);
      }}
    >
      <PopoverTrigger asChild>
        <Input
          id={props.id}
          leftSection={<CalendarDays />}
          classNames={{
            wrapper: cn(
              'w-fit cursor-pointer',
              open && '!border-content-brand-primary',
              triggerClassnames?.wrapper,
            ),
            input: cn('cursor-pointer', triggerClassnames?.input),
          }}
          value={showDate()}
          onChange={() => {}}
          bigness={size}
          placeholder="Pick a date"
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0 border-none bg-white rounded-lg"
        align="start"
      >
        <Calendar
          {...props}
          mode="single"
          showOutsideDays={false}
          selected={date}
          onSelect={setDate}
          footer={
            <div className="grid grid-cols-2 gap-3">
              <PopoverClose asChild>
                <Button size="sm" variant="soft" className="w-full">
                  Cancel
                </Button>
              </PopoverClose>
              <Button size="sm" className="w-full" onClick={onApply}>
                Apply
              </Button>
            </div>
          }
        />
      </PopoverContent>
    </Popover>
  );
};

const DatePickerRange: React.FC<
  DatePickerSpecificProps & (PropsRange | PropsRangeRequired)
> = ({ selected, onSelect, size = 'lg', triggerClassnames, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState<DateRange | undefined>(selected);

  const isSameDay =
    date?.from && date?.to ? compareAsc(date?.from, date?.to) === 0 : true;

  const showDate = () => {
    if (selected) {
      return selected?.from
        ? selected.to
          ? format(selected.from, 'd MMM, yyyy') +
            ' - ' +
            format(selected.to, 'd MMM, yyyy')
          : format(selected.from, 'd MMM, yyyy')
        : '';
    }
    return '';
  };

  const onApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (date) {
      onSelect && onSelect(date, new Date(), {}, e);
      setOpen(false);
    }
  };

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) setDate(selected);
      }}
    >
      <PopoverTrigger>
        <Input
          id={props.id}
          leftSection={<CalendarDays />}
          classNames={{
            wrapper: cn(
              'w-fit cursor-pointer',
              open && '!border-content-brand-primary',
              triggerClassnames?.wrapper,
            ),
            input: cn('cursor-pointer', triggerClassnames?.input),
          }}
          bigness={size}
          value={showDate()}
          onChange={() => {}}
          placeholder="Pick a date"
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0 border-none bg-white rounded-lg"
        align="start"
      >
        <Calendar
          {...props}
          mode="range"
          showOutsideDays={false}
          defaultMonth={date?.from}
          selected={date}
          onSelect={setDate}
          numberOfMonths={2}
          footer={
            <div className="flex items-center gap-3">
              <div className="flex items-center text-sm gap-1">
                <span className="text-black-300">Range: </span>
                <span className="text-black font-semibold">
                  {date?.from && date.to
                    ? `${format(date.from, 'd MMM, yyyy')} - ${format(date.to, 'd MMM, yyyy')}`
                    : ''}
                </span>
              </div>
              <div className="flex items-center gap-3 ml-auto">
                <PopoverClose asChild>
                  <Button variant="soft" size="sm" className="w-full">
                    Cancel
                  </Button>
                </PopoverClose>
                <Button
                  disabled={isSameDay}
                  size="sm"
                  className="w-full"
                  onClick={onApply}
                >
                  Apply
                </Button>
              </div>
            </div>
          }
        />
      </PopoverContent>
    </Popover>
  );
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
