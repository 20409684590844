import { ReactNode } from 'react';

import cn from '../lib/cn';
import { TabsContent, TabsCore, TabsList, TabsTrigger } from './tabs.core';

type TabItem = {
  label: ReactNode;
  content: ReactNode;
  icon?: ReactNode;
};

export type TabsProps<T extends string = string> = {
  classnames?: {
    tabs?: string;
    tabsList?: string;
    tabsTrigger?: string;
    tabsContent?: string;
  };
  tabs: Array<TabItem & { key: T }>;
  pill?: true;
  forceMount?: true;
  defaultValue?: T;
  onValueChange?: ((value: string) => void) | undefined;
};

function Tabs({
  classnames,
  tabs,
  defaultValue,
  pill,
  forceMount,
  onValueChange,
}: TabsProps) {
  const navigation = tabs.map((tab) => {
    const { icon = null, label, key } = tab;
    return (
      <TabsTrigger
        key={key}
        value={key}
        pill={pill}
        className={cn(classnames?.tabsTrigger)}
      >
        {icon}
        <span>{label}</span>
      </TabsTrigger>
    );
  });

  const contents = tabs.map((tab) => (
    <TabsContent
      key={tab.key}
      value={tab.key}
      forceMount={forceMount}
      className={cn(classnames?.tabsContent)}
    >
      {tab.content}
    </TabsContent>
  ));

  return (
    <TabsCore
      defaultValue={defaultValue}
      className={cn('w-full', classnames?.tabs)}
      onValueChange={onValueChange}
    >
      <TabsList className={cn(classnames?.tabsList)} pill={pill}>
        {navigation}
      </TabsList>
      {contents}
    </TabsCore>
  );
}

Tabs.displayName = 'Tabs';

export default Tabs;
